// eslint-disable-next-line no-undef
window._config = {
  baseUrl: 'https://login.bandwidth.com',
  dashboard: 'https://dashboard.bandwidth.com',
  irisApi: 'https://dashboard.bandwidth.com/api/v2',
  oneIdManagementApi: 'https://management.id.bandwidth.com',
  oidc: {
    issuer: 'https://login.bandwidth.com/oauth2/aus6z3sybxny29ou82p7',
    clientId: '0oa9a0otabTk4PaXd2p7',
    redirectUri: 'https://passport.bandwidth.com/login',
    postLogoutRedirectUri: 'https://passport.bandwidth.com/logout',
    scopes: ['openid', 'oneid', 'email', 'profile'],
    pkce: true,
    storageManager: {
      token: {
        storageTypes: ['sessionStorage'],
      },
    },
  },
  cookies: {
    secure: true,
  },
  datadog: {
    env: 'production',
    applicationId: 'acf07da9-5252-4195-9e05-146de9d0f631',
    clientToken: 'pub3d2ac3ded2c8c500a7ca1e4f1e2394fe',
  },
  allowedReturnToUrls: [
    'dashboard.bandwidth.com',
    'accounts.bandwidth.com',
    'numbers-history-app.dashboard.bandwidth.com',
    'app.bandwidth.com',
    'arke.iris.prod.global.aws.bandwidth.com',
  ],
};
